import React from "react";

const ProfileCard = ({ imageUrl, altText, onClick }) => {
  return (
    <div
      className="text-center psize"
      style={{
        // backgroundColor: "#6CB3E1",
        padding: "0px",
        borderRadius: "50%", // Ensures the container is round
        marginTop: "0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.15)", // Adds shadow for a clean look
      }}
    >
      {/* Internal CSS for responsiveness */}
      <style>
        {`
          .psize {
            width: 400px;
            height: 400px;
            border-radius: 50%;
          }

          /* For tablets */
          @media (max-width: 1024px) {
            .psize {
              width: 250px;
              height: 250px;
            }
          }

          /* For mobile devices */
          @media (max-width: 600px) {
            .psize {
              width: 170px;
              height: 170px;
            }
          }
        `}
      </style>

      {/* Profile Image */}
      <img
        src={imageUrl}
        alt={altText}
        className="img-fluid rounded-circle"
        onClick={onClick} // Optional onClick handler
      />
    </div>
  );
};

export default ProfileCard;
