import React, { useState } from "react";
import { createProfileUrl , getAllCityByStateID} from "../../services/ProfileService";
import { notifySuccess, notifyError } from '../../services/NotificationService';
import UserProfile from "./UserProfile";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Step1 from "../Profile/step1";
import Step2 from "../Profile/step2";
import Step3 from "../Profile/step3";
import { ageShouldGreaterThan18 } from "../../utils/formUtils";
import LoadingSpinner from "../Loader/loader";
import {getAccessToken, getRawToken } from '../../utils/commonutils';
import {jwtDecode} from 'jwt-decode';

// import Step4 from "../Profile/step4";


function Profile() {

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [cities, setCities] = useState([]);
  const states = useSelector((state) => state.indata.state_data);
  const pdata = useSelector((state) => state.indata.user_profile_exist_or_not);
  const choices = useSelector((state) => state.indata.all_choice_field_from_backend);
  const navigate = useNavigate();
  const tkn = getAccessToken()
  const rtoken = getRawToken(tkn)
  const decodedToken = jwtDecode(rtoken);
  const is_outsider = decodedToken.is_outsider
  

  const [formData, setFormData] = useState({
    full_name:"",
    date_of_birth: "",
    gender: "",
    revert: "",
    deeni_interest: [],
    phone_number: "",
    native_state_language: "",
    resident_state_language: "",
    state: "",
    city: "",
    address: "",
    marital_status: "",
    height: "",
    weight: "",
    about_me: "",
    about_family: "",
    father_name: "",
    father_occupation: "",
    mother_name: "",
    mother_occupation: "",
    education: "",
    profession: "",
    monthly_salary_range: "",
    partner_revert: "",
    partner_requirement: "",
    photo1: null,
    photo2: null,   
  });



  // Function to validate required fields for each step
  const validateStep = () => {
    const requiredFieldsStep1 = ["native_state_language","full_name","native_state_language","date_of_birth", "gender", "phone_number",
    "deeni_interest", "revert"];
    const requiredFieldsStep2 = ["marital_status","profession",'monthly_salary_range',"education","state", "city"];
    const requiredFieldsStep3 = ["height", "weight"];

    let missingFields = [];

    switch (step) {
      case 1:
        missingFields = requiredFieldsStep1.filter(field => !formData[field]);
        const ageError = ageShouldGreaterThan18(formData.date_of_birth);
        if (ageError){
          notifyError(ageError)
          return; 
        }

          // Check if the contact number contains only digits
        if (!/^\d+$/.test(formData.phone_number)) {
            alert('The mobile number must contain only digits.');
            return;
        }


        // Check if the contact number has exactly 10 digits
        if (formData.phone_number.length !== 10) {
            alert('The mobile number must be exactly 10 digits long.');
            return;
        }   
        break;
      case 2:
        missingFields = requiredFieldsStep2.filter(field => !formData[field]);
        break;
      case 3:
        missingFields = requiredFieldsStep3.filter(field => !formData[field]);
        break;
      default:
        break;
    }

    return missingFields;
  };


   // Fetch cities when the selected state changes
  const handleStateChange = async (e) => {
    const selectedState = e.target.value;

    // Update formData state
    setFormData((prevData) => ({
        ...prevData,
        state: selectedState, 
        city: "",
    }));

    if (selectedState) {
      try {
        const response = await getAllCityByStateID(selectedState); 
        setCities(response.data); 
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    } else {
      setCities([]); // Clear cities if no state is selected
    }
    };

    const today = new Date().toISOString().split('T')[0]; // Ensure today is in 'YYYY-MM-DD' format

    const handleChange = (e) => {
      const { name, value, files, options, type, checked } = e.target;
    
      if (files) {
        // Handle file inputs
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: files[0], // Save the first file
        }));
      } else if (type === "select-multiple") {
        // Handle multi-select fields (dropdowns)
        const selectedValues = Array.from(options) // Convert options to an array
          .filter((option) => option.selected) // Get only selected options
          .map((option) => option.value); // Extract the value of each selected option
    
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: selectedValues, // Save as an array of selected values
        }));
      } else if (type === "checkbox") {
        // Handle checkboxes
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: checked
            ? [...(prevFormData[name] || []), value] // Add the value if checked
            : prevFormData[name]?.filter((item) => item !== value) || [], // Remove if unchecked
        }));
      } else {
        // Handle other input fields
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value, // Save the value
        }));
      }
    
      console.log("Updated Form Data:", formData); // Debugging log to view the updated state
    };
    
    

  // Function to handle moving to the next step
  const handleNext = () => {
    const missingFields = validateStep();
    if (missingFields.length > 0) {
      // Show an error notification for the missing fields
      notifyError(`Please fill: ${missingFields.join(", ")}`);
      return; 
    }
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

      // Check if photo1 and photo2 are filled
    if (!formData.photo1 || !formData.photo2) {
      // Show an error message if either photo1 or photo2 is missing
      notifyError("Please fill in both the photo1 and photo2 fields before submitting.");
      return;  // Prevent form submission
    }
  
    const formDataToSubmit = new FormData();
  
    // Loop through the form data and append it to formDataToSubmit
    for (const key in formData) {
      formDataToSubmit.append(key, formData[key]);
    }
  

    try {
      // Use the centralized createProfileUrl function to submit the form data
      setLoading(true);
      await createProfileUrl(formDataToSubmit);
      setLoading(false);
      notifySuccess("Profile created !");
      navigate("/")

      // Handle successful response (e.g., show a success message or redirect)
    } catch (error) {
      setLoading(false);
      notifyError(e.message || 'Please try again later.');
      // Handle error (e.g., show an error message)
    }
  };


  if (loading) {
    return <LoadingSpinner visible={true} />;
  }

      // Display message for the outsider
      if (is_outsider) {
        return (
          <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
            <div className="text-center w-75 w-md-50 p-4 border-0 shadow-sm rounded-3">
              <h2 className="mb-4">
                You cannot create the profile, as you do not know Khurshid Imam
              </h2>
              <button
                className="btn btn-primary"
                onClick={() => navigate('/')} // Redirects to the home page
              >
                Go to Home
              </button>
            </div>
          </div>
        );
      }
    

  return (
    <div className="container mt-5">
        {pdata.profile_exists ? (
          <>
              <UserProfile profileData={pdata.profile_data}  is_editable={true}  />
          </>
        ) : (
          <>
          <div className="text-center mb-4">
            <h4>Step {step} of 4</h4>
          </div>
          <h1 className="text-center mb-4">Create Profile</h1>
          <form className="bg-light p-4 rounded shadow" onSubmit={handleSubmit}  encType="multipart/form-data">

            {step === 1 && (
              <>
              <Step1
                      formData={formData}
                      handleChange={handleChange}
                      handleNext={handleNext}
                      today={today}
                      choices={choices}
                />
              </>
            )}

            {step === 2 && (
              <>
                <Step2
                  formData={formData}
                  handleChange={handleChange}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  choices={choices}
                  states={states}
                  cities={cities}
                  handleStateChange={handleStateChange}
                />
              </>
            )}


            {step === 3 && (
              <>
                {/* Render Step3 and pass necessary props */}
                <Step3
                  formData={formData}
                  handleChange={handleChange}
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              </>
            )}

            {step === 4 && (
              <>
                    {/* Render Step4 and pass necessary props */}
                    <div className="form-group mb-3">
                    <label htmlFor="address">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                    />
                  </div>

                  {/* About Me */}
                  <div className="form-group mb-3">
                    <label htmlFor="about_me">About Me</label>
                    <textarea
                      className="form-control"
                      name="about_me"
                      value={formData.about_me}
                      onChange={handleChange}
                      rows="3"
                      required
                    />
                  </div>

                  {/* About Family */}
                  <div className="form-group mb-3">
                    <label htmlFor="about_family">About Family</label>
                    <textarea
                      className="form-control"
                      name="about_family"
                      value={formData.about_family}
                      onChange={handleChange}
                      rows="3"
                    />
                  </div>


                  {/* Partner Revert */}
                  <div className="form-group mb-3">
                    <label>Looking For Revert</label>
                    <select
                      className="form-control"
                      name="partner_revert"
                      value={formData.partner_revert}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Both">Both</option>
                    </select>
                  </div>


                    {/* Partner Requirement */}
                  <div className="form-group mb-3">
                    <label htmlFor="partner_requirement">Partner Preferences</label>
                    <textarea
                      className="form-control"
                      name="partner_requirement"
                      value={formData.partner_requirement}
                      onChange={handleChange}
                      rows="3"
                      required
                    />
                  </div>

                  {/* Photo Upload 1 */}
                  <div className="form-group mb-3">
                    <label htmlFor="photo1">Upload Photo 1</label>
                    <input
                      type="file"
                      className="form-control"
                      name="photo1"
                      onChange={handleChange}
                      accept="image/*"
                    />
                  </div>

                  {/* Photo Upload 2 */}
                  <div className="form-group mb-3">
                    <label htmlFor="photo2">Upload Photo 2</label>
                    <input
                      type="file"
                      className="form-control"
                      name="photo2"
                      onChange={handleChange}
                      accept="image/*"
                    />
                  </div>

                {/* Navigation Buttons */}
                <div className="d-flex justify-content-between">
                  <button type="button" className="btn btn-secondary" onClick={handleBack}>
                    Back
                  </button>
                  <button type="submit" className="btn btn-success mx-4">
                    Submit
                  </button>
                </div>
              </>
              
            )}


        </form>

        </>)}
    </div>
  );
}

export default Profile;
