import React, { useEffect, useState } from 'react';
import { getUserProfileById } from '../../services/ProfileService';
import { useParams, useNavigate } from 'react-router-dom';

const SingleUserProfile = () => {
  const { id } = useParams(); 
  const [profileData, setProfileData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user data from API
    const fetchUserData = async (id) => {
      const response = await getUserProfileById(id);
      setProfileData(response.data);
    };
    
    fetchUserData(id);
  }, [id]);

  if (!profileData) return <div className="text-center mt-5">Loading...</div>;

  return (
    <>
      <div className="container mt-5">
        {/* Personal Information Section */}
        <button className='my-2 btn btn-sm btn-primary' onClick={() => navigate(-1)}>Back</button> {/* Navigate back button */}

        <div className="card mb-4">
      

          <div className="card-header">
            <h5 className="mb-0">Personal Information</h5>
          </div>
          <div className="card-body">
          <div className="row">
            <div className="col-md-8 mb-2 d-flex justify-content-between">
              <div>
                <label className="form-label text-muted">Full Name:</label>
                <strong className="ms-2">{profileData.full_name || 'Not Provided'}</strong>
              </div>
              <div>
                <label className="form-label text-muted">Date of Birth:</label>
                <strong className="ms-2">{profileData.date_of_birth || 'Not Provided'}</strong>
              </div>
            </div>
          </div>

            <div className="row">
              <div className="col-md-6 mb-2">
                <label className="form-label text-muted">Gender:</label>
                <strong className="ms-2">{profileData.gender || 'Not Provided'}</strong>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Phone Number:</label>
                <strong className="ms-2">{profileData.phone_number || 'Not Provided'}</strong>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Height (cm):</label>
                <strong className="ms-2">{profileData.height || 'Not Provided'}</strong>

              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Weight (kg):</label>
                <strong className="ms-2">{profileData.weight || 'Not Provided'}</strong>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">About Me:</label>
                <strong className="ms-2">{profileData.about_me || 'Not Provided'}</strong>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Monthly Salary Range:</label>
                <strong className="ms-2">{profileData.monthly_salary_range || 'Not Provided'}</strong>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">State</label>
                <strong className="ms-2">{profileData.state_name || 'Not Provided'}</strong>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">City</label>
                <strong className="ms-2">{profileData.city_name || 'Not Provided'}</strong>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Native State Language</label>
                <strong className="ms-2">{profileData.native_state_language || 'Not Provided'}</strong>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Resident State Language</label>
                <strong className="ms-2">{profileData.resident_state_language || 'Not Provided'}</strong>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Education</label>
                <strong className="ms-2">{profileData.education || 'Not Provided'}</strong>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Profession</label>
                <strong className="ms-2">{profileData.profession || 'Not Provided'}</strong>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Deeni Interest</label>
                <strong className="ms-2">{profileData.deeni_interest || 'Not Provided'}</strong>
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Revert</label>
                <strong className="ms-2">{profileData.revert || 'Not Provided'}</strong>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Marital Status</label>
                <strong className="ms-2">{profileData.marital_status || 'Not Provided'}</strong>
              </div>
            </div>
          </div>
        </div>

        {/* Family Information Section */}
        <div className="card mb-4">
          <div className="card-header">
            <h5 className="mb-0">Family Information</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Father's Name</label>
                <strong className="ms-2">{profileData.father_name || 'Not Provided'}</strong>

              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Mother's Name</label>
                <strong className="ms-2">{profileData.mother_name || 'Not Provided'}</strong>

              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Father's Occupation</label>
                <strong className="ms-2">{profileData.father_occupation || 'Not Provided'}</strong>

              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Mother's Occupation</label>
                <strong className="ms-2">{profileData.mother_occupation || 'Not Provided'}</strong>

              </div>
            </div>


            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">About Family</label>
                <strong className="ms-2">{profileData.about_family || 'Not Provided'}</strong>

              </div>
            </div>
            
          </div>
        </div>

        {/* Partner Preferences Section */}
        <div className="card mb-4">
          <div className="card-header">
            <h5 className="mb-0">Partner Preferences</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Partner Revert</label>
                <strong className="ms-2">{profileData.partner_revert || 'Not Provided'}</strong>
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label text-muted">Partner Requirements</label>
                <strong className="ms-2">{profileData.partner_requirement || 'Not Provided'}</strong>
              </div>
            </div>
          </div>
        </div>

        {/* Images Section */}
        <div className="card mb-4">
          <div className="card-header">
            <h5 className="mb-0">Profile Images</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 mb-3">
                <img src={profileData.photo1} alt="Profile" className="img-fluid" />
              </div>
              <div className="col-md-6 mb-3">
                <img src={profileData.photo2} alt="Profile" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default SingleUserProfile;
